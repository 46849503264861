
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import DTCls from "@/utils/datetime";
import { saveAs } from "@/api/request/base";
import Pagination from "@/comp/Pagination/index.vue";
import { setPromoteUserAudit, getPromoteUserList, exportPromoteUserList } from "@/api/request/promotion";

//组件
@Component({
  name: "UserPromotion",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private total_amount: number = 0;
  private listLoading: boolean = false;

  //请求数据
  private listQuery: any = {
    //页面数据
    page: 1,
    page_size: 20,

    //零时数据
    idStr: "", //ID
    pidStr: "", //上级ID
    reg_time: [], //注册时间
    recharge_time: [], //充值时间

    //请求数据
    id: 0, //ID
    pid: 0, //上级ID
    reg_end_time: "", //注册结束时间
    reg_start_time: "", //注册开始时间
    recharge_end_time: "", //充值结束时间
    recharge_start_time: "", //充值开始时间
  };

  //时间选择器参数
  get pickerOptions(): any {
    return {
      shortcuts: [
        {
          text: "今日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getTodayStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "昨日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getYesterdayStartTime()), new Date(DTCls.getYesterdayEndTime())]);
          },
        },
        {
          text: "本周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurWeekStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastWeekStartTime()), new Date(DTCls.getLastWeekEdnTime())]);
          },
        },
        {
          text: "本月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurMonthStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastMonthStartTime()), new Date(DTCls.getLastMonthEndTime())]);
          },
        },
        {
          text: "最近一周",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近三个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "全部",
          onClick(picker: any) {
            picker.$emit("pick", []);
          },
        },
      ],
      disabledDate(time: Date) {
        return time.getTime() > Date.now();
      },
    };
  }

  //创建
  created() {
    //获取列表
    this.getList();
  }

  //获取列表
  private async getList() {
    //显示等待
    this.listLoading = true;

    //注册时间赋值
    if (this.listQuery.reg_time && this.listQuery.reg_time.length == 2) {
      this.listQuery.reg_end_time = `${this.listQuery.reg_time[1]} 23:59:59`;
      this.listQuery.reg_start_time = `${this.listQuery.reg_time[0]} 00:00:00`;
    } else {
      this.listQuery.reg_end_time = "";
      this.listQuery.reg_start_time = "";
    }

    //充值时间赋值
    if (this.listQuery.recharge_time && this.listQuery.recharge_time.length == 2) {
      this.listQuery.recharge_end_time = `${this.listQuery.recharge_time[1]} 23:59:59`;
      this.listQuery.recharge_start_time = `${this.listQuery.recharge_time[0]} 00:00:00`;
    } else {
      this.listQuery.recharge_end_time = "";
      this.listQuery.recharge_start_time = "";
    }

    //其他数据
    this.listQuery.id = Number(this.listQuery.idStr) > 0 ? Number(this.listQuery.idStr) : undefined;
    this.listQuery.pid = Number(this.listQuery.pidStr) > 0 ? Number(this.listQuery.pidStr) : undefined;

    //获取数据
    const { data } = await getPromoteUserList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;
    this.total_amount = data.total_amount;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //导出Excel
  private async handleExcel() {
    //显示等待
    this.listLoading = true;

    //注册时间赋值
    if (this.listQuery.reg_time && this.listQuery.reg_time.length == 2) {
      this.listQuery.reg_end_time = `${this.listQuery.reg_time[1]} 23:59:59`;
      this.listQuery.reg_start_time = `${this.listQuery.reg_time[0]} 00:00:00`;
    } else {
      this.listQuery.reg_end_time = "";
      this.listQuery.reg_start_time = "";
    }

    //充值时间赋值
    if (this.listQuery.recharge_time && this.listQuery.recharge_time.length == 2) {
      this.listQuery.recharge_end_time = `${this.listQuery.recharge_time[1]} 23:59:59`;
      this.listQuery.recharge_start_time = `${this.listQuery.recharge_time[0]} 00:00:00`;
    } else {
      this.listQuery.recharge_end_time = "";
      this.listQuery.recharge_start_time = "";
    }

    //其他数据
    this.listQuery.id = Number(this.listQuery.idStr) > 0 ? Number(this.listQuery.idStr) : undefined;
    this.listQuery.pid = Number(this.listQuery.pidStr) > 0 ? Number(this.listQuery.pidStr) : undefined;

    //获取数据
    const { data } = await exportPromoteUserList(this.listQuery);

    //保存数据
    saveAs(data, "用户推广列表");

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理查询
  private handleSearch(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取数据
    this.getList();
  }

  //批准领取
  private async handleGet(row: any) {
    //显示等待
    this.listLoading = true;

    //批准领取
    await setPromoteUserAudit({ user_id: row.id, status: 1 });

    //隐藏等待
    setTimeout(() => {
      //隐藏等待
      this.listLoading = false;

      //获取数据
      this.getList();
    }, 0.5 * 1000);
  }
}
